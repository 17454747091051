import https from '../service'
interface postData {
  url: string
}
const getWxJsSDKConfigInfo = (params: postData) => {
  return https.post({
    url: '/qsApi/WxPay/getWxJsSDKConfigInfo',
    params
  })
}
export { getWxJsSDKConfigInfo }
